import TagManager from 'react-gtm-module';

export const initializeTagManager = () => {
  const tagCode = process.env.REACT_APP_GTM_CODE;

  if (tagCode) {
    const tagManagerArgs = {
      gtmId: tagCode,
    };

    TagManager.initialize(tagManagerArgs);
  }
};
