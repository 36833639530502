import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Container, Row, Badge } from 'react-bootstrap';

import useBreakPoints from '../../hooks/useBreakPoints';

import { formatDate, isCurrentDate } from '../../utils';

import PriceBox from '../PriceBox';
import LeasingInterest from '../LeasingInterest';
import { Text, Title } from '../UIComponents/Typography';

export default function ListCard({ car, handleModal }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { modelFamily, retailer } = useParams();
  const { isSmallerThanMd, isXs } = useBreakPoints();
  const {
    ModelName,
    ThumbnailBig,
    MonthlyPmt,
    Prices,
    ETA,
    SpecialInterest,
    Accessories,
    ColorName,
    IsHybrid,
    URN,
    InterestRate,
    FiveYearsWarranty,
    InteriourName,
    ReservedUntil,
    ModelCodeForGTag,
    ModelNameForGTag,
    CampaignIcons,
    EuriborText,
  } = car;

  const availableFrom = formatDate(ETA);
  const isCarAvailable = isCurrentDate(ETA);

  const handleChooseVehicle = urn => {
    window.tngaDataLayer.event = {
      name: 'workflowevent',
      componentName: 'stock-cars-search',
      componentGroup: 'tools',
      action: 'workflow-step',
      label: 'offer-details',
    };
    window.tngaDataLayer.workflow = {
      name: 'stock-cars-search',
      step: 'offer-details',
      conversion: '0',
    };
    window.tngaDataLayer.product = [
      {
        category: 'cars',
        subCategory: 'new-cars',
        productInfo: {
          productId: ModelCodeForGTag,
          productName: ModelNameForGTag,
          tmeModelId: ModelCodeForGTag,
          modelName: ModelNameForGTag,
        },
      },
    ];
    window.dataLayer.push({ event: 'workflowevent' });
    history.push(`${retailer ? `/${retailer}` : ''}/${modelFamily}/${urn}`);
  };

  const setAvailability = string => {
    const splitString = string.split(':');
    return (
      <>
        {`${splitString[0]}: `}
        <strong>{splitString[1]} </strong>
      </>
    );
  };

  return (
    <Container className="list-card mb-6" id={URN} fluid>
      <Row xs={1} md={2}>
        <Col md={5}>
          <Row className="justify-content-center align-items-start position-relative">
            {ReservedUntil ? (
              <Badge className="position-absolute" variant="light">
                {t('Booked')}
              </Badge>
            ) : null}
            <img
              className="model-img"
              src={ThumbnailBig}
              alt="model"
              onClick={() => handleChooseVehicle(URN)}
            />
          </Row>
          <LeasingInterest
            CampaignIcons={CampaignIcons}
            SpecialInterest={SpecialInterest}
            InterestRate={InterestRate}
            IsHybrid={IsHybrid}
            FiveYearsWarranty={FiveYearsWarranty}
          />
        </Col>
        <Col md={7}>
          <Row className="px-2">
            <Title size="4">{ModelName}</Title>
          </Row>
          <Row xs={1} md={2}>
            <Col className="details px-4 mt-2">
              <Row className="flex-column">
                <Text>
                  {t('Color')}: <Text strong>{ColorName}</Text>
                </Text>
                {InteriourName ? (
                  <Text>
                    {t('InteriorColour')} <Text strong>{InteriourName}</Text>
                  </Text>
                ) : null}
                {Accessories ? (
                  <Text>
                    {t('Accessories')}{' '}
                    <Text strong>{Accessories.join(', ')}</Text>
                  </Text>
                ) : null}
                <Text className="mt-md-2 mt-lg-4 mb-3">
                  {isCarAvailable ? (
                    <span className="text-capitalize">
                      {setAvailability(t('Available'))}
                    </span>
                  ) : (
                    <Trans i18nKey="AvailableFrom">
                      Saadavus:
                      <strong className="text-capitalize">
                        alates {{ availableFrom }}
                      </strong>
                    </Trans>
                  )}
                </Text>
              </Row>
              <Row>
                <Button
                  variant="secondary"
                  className="mb-2"
                  onClick={() => handleChooseVehicle(URN)}
                  block={isSmallerThanMd}
                >
                  {t('ShowDetails')}
                </Button>
              </Row>
            </Col>
            <Col className="p-0 mt-2 d-flex flex-column justify-content-between">
              <PriceBox
                MonthlyPmt={MonthlyPmt}
                Prices={Prices}
                InterestRate={InterestRate}
                IsHybrid={IsHybrid}
                SpecialInterest={SpecialInterest}
                EuriborText={EuriborText}
              />
              <Button
                className="mb-2"
                block
                size="sm"
                onClick={() => handleModal(URN)}
              >
                {t('RequestInformation')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {isXs ? <hr className="hr-thick" /> : null}
    </Container>
  );
}
