import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import Switch from 'react-bootstrap/esm/Switch';

import { compareArrayNumbers } from '../../utils';

import Loader from '../Loader';
import { CustomRange } from '../UIComponents/Slider';
import { Text } from '../UIComponents/Typography';

import { ReactComponent as Door } from '../../assets/images/Door.svg';
import { ReactComponent as Pump } from '../../assets/images/Pump.svg';
import { ReactComponent as CO2 } from '../../assets/images/CO2.svg';
import { ReactComponent as Engine } from '../../assets/images/Engine.svg';
import { ReactComponent as Rims } from '../../assets/images/Rims.svg';
import { ReactComponent as Tag } from '../../assets/images/Tag.svg';
import { ReactComponent as Check } from '../../assets/images/Check.svg';

const Filters = ({
  filters,
  isLoading,
  filterValues,
  setFilterValues,
  setHasFilterChanged,
}) => {
  const { t } = useTranslation();

  const filtersToRender = { ...filters };

  for (const filter in filtersToRender) {
    if (Object.hasOwnProperty.call(filtersToRender, filter)) {
      if (filtersToRender[filter].length <= 1) delete filtersToRender[filter];
    }
  }
  delete filtersToRender.ModelFamilyName;

  const handleFilterChange = (value, filter) => {
    setHasFilterChanged(true);
    if (filter === 'CO2' || filter === 'PriceRange') {
      setFilterValues(prevState => ({ ...prevState, [filter]: value }));
    } else {
      if (!filterValues[filter].length) {
        setFilterValues(prevState => ({ ...prevState, [filter]: [value] }));
      } else {
        if (filterValues[filter].includes(value)) {
          const values = filterValues[filter].filter(val => val !== value);
          setFilterValues(prevState => ({
            ...prevState,
            [filter]: values,
          }));
        } else {
          setFilterValues(prevState => ({
            ...prevState,
            [filter]: [...prevState[filter], value],
          }));
        }
      }
    }
  };

  const renderFilters = filterType => {
    switch (filterType) {
      case 'FuelTypes':
        return (
          <>
            <Text className="filter-label d-flex align-items-center">
              {!filterValues?.[filterType].length ? (
                <Pump />
              ) : (
                <Check className="checked" />
              )}{' '}
              {t('FuelType')}
            </Text>
            <Row className="justify-content-start mt-3 mb-4 flex-column">
              {filtersToRender[filterType].map(
                ({ FuelType, FuelName }, idx) => (
                  <React.Fragment key={FuelName}>
                    <Switch
                      className={`custom-switch-lg ${idx === 0 && 'mr-2'}`}
                      id={FuelType + '-fuel'}
                      label={FuelName}
                      onChange={() => handleFilterChange(FuelType, filterType)}
                    />
                  </React.Fragment>
                )
              )}
            </Row>
          </>
        );
      case 'TransmissionTypes':
        return (
          <>
            <Text className="filter-label d-flex align-items-center">
              {!filterValues?.[filterType].length ? (
                <Engine />
              ) : (
                <Check className="checked" />
              )}{' '}
              {t('Transmission')}
            </Text>
            <Row className="justify-content-start mt-3 mb-4 flex-column">
              {filtersToRender[filterType].map(({ Name, Value }, idx) => (
                <React.Fragment key={Name}>
                  <Switch
                    className={`custom-switch-lg ${idx === 0 && 'mr-2'}`}
                    id={Value + '-transmission'}
                    label={Name}
                    onChange={() => handleFilterChange(Value, filterType)}
                  />
                </React.Fragment>
              ))}
            </Row>
          </>
        );
      case 'BodyTypes':
        return (
          <>
            <Text className="filter-label d-flex align-items-start">
              {!filterValues?.[filterType].length ? (
                <Door />
              ) : (
                <Check className="checked" />
              )}{' '}
              {t('BodyType')}
            </Text>
            <Row className="justify-content-start mt-3 mb-4 flex-column">
              {filtersToRender[filterType].map(({ Id, Value }, idx) => (
                <Col key={Id}>
                  <Switch
                    className={`custom-switch-lg ${idx === 0 && 'mr-2'}`}
                    id={Id + '-body'}
                    label={Value}
                    onChange={() => handleFilterChange(Id, filterType)}
                  />
                </Col>
              ))}
            </Row>
          </>
        );
      case 'DriveTrainTypes':
        return (
          <>
            <Text className="filter-label d-flex align-items-center">
              {!filterValues?.[filterType].length ? (
                <Rims />
              ) : (
                <Check className="checked" />
              )}{' '}
              {t('DriveTrainType')}
            </Text>
            <Row className="justify-content-start mt-3 mb-4 flex-column">
              {filtersToRender[filterType].map(({ Id, Name }, idx) => (
                <Col key={Id}>
                  <Switch
                    className={`custom-switch-lg ${idx === 0 && 'mr-2'}`}
                    id={Id + '-driveTrain'}
                    label={Name}
                    onChange={() => handleFilterChange(Id, filterType)}
                  />
                </Col>
              ))}
            </Row>
          </>
        );
      case 'PriceRange':
        return (
          <>
            <Text className="filter-label d-flex align-items-center">
              {compareArrayNumbers(
                filterValues[filterType],
                filters[filterType]
              ) ? (
                <Tag />
              ) : (
                <Check className="checked" />
              )}{' '}
              {t('Price')}
            </Text>
            <CustomRange
              min={filters[filterType][0]}
              max={filters[filterType][1]}
              sliderValuesSufix={'€'}
              sliderValue={filterValues.PriceRange}
              onSliderChange={value => handleFilterChange(value, filterType)}
            />
          </>
        );
      case 'CO2':
        return (
          <>
            <Text className="filter-label d-flex align-items-center">
              {compareArrayNumbers(
                filterValues[filterType],
                filters[filterType]
              ) ? (
                <CO2 />
              ) : (
                <Check className="checked" />
              )}{' '}
              {t('CoPrefix')}
              <sub className="pr-1">2</sub>
              {t('CO')}
            </Text>
            <CustomRange
              min={filters[filterType][0]}
              max={filters[filterType][1]}
              sliderValue={filterValues.CO2}
              sliderValuesSufix={t('COUnit')}
              onSliderChange={value => handleFilterChange(value, filterType)}
            />
          </>
        );
      default:
        break;
    }
  };

  if (isLoading)
    return (
      <Container fluid className="filters">
        <Loader />
      </Container>
    );

  return (
    <div className="filters">
      <Container fluid className="px-5 py-5 px-xl-10 mt-4 mb-6">
        <Row className="justify-content-around">
          {filters &&
            Object.keys(filtersToRender).map((filter, idx) => (
              <Col
                xs={12}
                md={6}
                lg={Math.round(12 / Object.keys(filtersToRender).length)}
                key={idx}
              >
                {renderFilters(filter)}
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default Filters;
