import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

export const Text = ({ children, ...rest }) => {
  const classes = classNames('text', rest.className, {
    'text-uppercase': rest.uppercase,
    'text-strong': rest.strong,
    'text-italic': rest.italic,
    [`text-${rest.type}`]: rest.type,
    [`text-${rest.size}`]: rest.size,
  });

  return <span className={classes}>{children}</span>;
};

Text.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'lg', 'xxl']),
  uppercase: PropTypes.bool,
  strong: PropTypes.bool,
  italic: PropTypes.bool,
  type: PropTypes.oneOf(['error', 'disabled', 'info']),
  children: PropTypes.node.isRequired,
};
