import React from 'react';

import './style.scss';

const Radio = ({ onClick, checked, id, ...props }) => (
  <span className="radio__wrap" {...props} onClick={onClick}>
    <input type="radio" checked={checked} id={id} />{' '}
    <span className="radio" role="radio" aria-checked={checked}>
      <span className="radio__inner"></span>
      <span className="radio__deco"></span>
    </span>
  </span>
);

export default Radio;
