import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';

import Loader from '../Loader';
import { Text, Title } from '../UIComponents/Typography';

const iconColor = '#8B8C90';

export default function Equipment({ list = {}, isLoading }) {
  const { t } = useTranslation();

  const columns = [[], []];
  const subEquipment = {};

  for (const [idx, pack] of Object.keys(list).entries()) {
    if (idx < Object.keys(list).length / 2) {
      columns[0].push(pack);
    } else {
      columns[1].push(pack);
    }
  }

  for (const type in list) {
    if (Object.hasOwnProperty.call(list, type)) {
      const element = list[type];
      const subEquipments = element.filter(
        ({ SubEquipment }) => Object.keys(SubEquipment).length
      );
      subEquipments.forEach(({ SubEquipment }) => {
        const subEquipmentKey = Object.keys(SubEquipment)[0];
        if (!subEquipment.length) {
          subEquipment[type] = SubEquipment;
        }
        if (!subEquipment?.[type]?.[subEquipmentKey]) {
          const prevSubs = { ...subEquipment[type] };
          subEquipment[type] = [...prevSubs, SubEquipment];
        }
      });
    }
  }

  if (isLoading) return <Loader />;

  return (
    <Container fluid className="equipment mb-5 px-2 px-lg-5 px-xl-7">
      <Title className="text-center mt-5 mb-6" size="2">
        {t('Equipment')}
      </Title>
      <Row xs={1} md={2} className="px-2 px-md-5">
        {columns.map((col, idx) => (
          <Col key={idx}>
            {col.map(pack => (
              <React.Fragment key={pack}>
                <Text strong>
                  <Icon
                    className="mr-3"
                    path={mdiCheck}
                    size={1}
                    color={iconColor}
                  />
                  {pack}:
                </Text>
                <div className="d-flex flex-column pl-45 ml-2 mt-1">
                  {list[pack].map(({ Name }) => (
                    <Text type="disabled" key={Name}>
                      {Name}
                    </Text>
                  ))}
                </div>
                {subEquipment?.[pack]
                  ? Object.keys(subEquipment[pack]).map(key => (
                      <div className="mt-2" key={key}>
                        <Text strong>
                          <Icon
                            className="mr-3"
                            color={iconColor}
                            path={mdiCheck}
                            size={1}
                          />
                          {key}
                        </Text>
                        <div className="d-flex flex-column pl-45 ml-2 mt-1">
                          {subEquipment[pack][key].map(({ Name }) => (
                            <Text type="disabled" key={Name}>
                              {Name}
                            </Text>
                          ))}
                        </div>
                      </div>
                    ))
                  : null}
                <hr />
              </React.Fragment>
            ))}
          </Col>
        ))}
      </Row>
    </Container>
  );
}
