import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

import './style.scss';

const Option = ({ active, value, onClick }) => (
  <Dropdown.Item className={active ? 'active' : ''} onClick={onClick}>
    {value}
  </Dropdown.Item>
);

const Select = ({ activeId, options, onClick, id, flex = false }) => {
  const { t } = useTranslation();

  const getSelectValue = () =>
    options !== undefined
      ? options.filter(item => item.id === activeId)[0].value
      : 'toyota.best.offers.select.no.options';

  return (
    <Dropdown className={flex ? 'flex' : ''}>
      <Dropdown.Toggle id={id}>{t(getSelectValue())}</Dropdown.Toggle>
      <Dropdown.Menu>
        {options?.map(item => (
          <Option
            key={item.id}
            active={activeId === item.id}
            value={t(item.value)}
            onClick={() => onClick(item.id)}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Select;
