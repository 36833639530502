import { useQuery } from 'react-query';

import { apiRequest } from '../utils/apiUtils';

export default function useFilters(modelFamily) {
  return useQuery(
    ['filters', modelFamily],
    () => apiRequest(`/api/vehicles/${modelFamily}/search`),
    {
      refetchOnWindowFocus: false,
    }
  );
}
