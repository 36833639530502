import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const languages = ['ee', 'ru', 'lv', 'lt'];

function createLanguageBackendPath() {
  return {
    loadPath: '/api/resources',
  };
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    backend: createLanguageBackendPath(),
    fallbackLng: languages[0],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    whitelist: languages,
    detection: {
      order: ['localstorage'],
      checkWhitelist: true,
      possibleLanguages: languages,
    },
  });

export default i18n;
