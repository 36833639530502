import { useQuery } from 'react-query';

import { apiRequest } from '../utils/apiUtils';

export default function useLeasing(regBody) {
  const { downp, residual, period, interest, price, leasingtype } = regBody;

  return useQuery(
    ['leasing', regBody],
    () =>
      apiRequest(
        `/api/model/leasing/${downp}/${residual}/${period}/${interest}/${price}/${leasingtype}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
}
