import './style.scss';

const CheckBox = ({ onChange, checked = false, id, ...props }) => {
  const handleClick = () => onChange({ target: { id, value: !checked } });

  return (
    <span onClick={handleClick}>
      <input onChange={handleClick} type="checkbox" checked={checked} id={id} />
      <span
        className="checkbox"
        role="checkbox"
        aria-checked={checked}
        {...props}
      >
        <span className="checkbox__inner"></span>
        <span className="checkbox__deco"></span>
      </span>
    </span>
  );
};

export default CheckBox;
