import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams, useLocation } from 'react-router-dom';
import { Button, Container, Row } from 'react-bootstrap';

import useVehicle from '../hooks/useVehicle';
import useEquipment from '../hooks/useEquipment';

import Leasing from '../components/DetailView/Leasing';
import Equipment from '../components/DetailView/Equipment';
import DetailViewHero from '../components/DetailView/DetailViewHero';
import Loader from '../components/Loader';
import Disclaimers from '../components/Disclaimers';
import ToyotaStripe from '../components/ToyotaStripe';
import { removeLastPathSegment } from '../utils';

const DetailView = () => {
  const { vehicleId, retailer } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  let { pathname, search, hash } = useLocation();
  const { isLoading, data = {} } = useVehicle(vehicleId);
  const { isLoading: equipmentLoading, data: equipment } =
    useEquipment(vehicleId);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isOfferModalVisible, setIsOfferModalVisible] = React.useState(false);

  const handleFriendModal = () => setIsModalVisible(!isModalVisible);

  const handleOfferModal = () => setIsOfferModalVisible(!isOfferModalVisible);

  const handleOtherBtn = () => history.push(`/${retailer ? retailer : ''}`);

  if (isLoading) return <Loader />;

  if (data && !isLoading) {
    const {
      CurrentPrice,
      SpecialInterest,
      InterestRate,
      ModelFamilyNameInCarDb,
    } = data;

    const handleBackBtn = () =>
      history.push(
        `${
          retailer ? `/${retailer}` : ''
        }/${ModelFamilyNameInCarDb}?selected=${vehicleId}`
      );

    return (
      <div className="detail-view">
        <ToyotaStripe />
        <DetailViewHero
          vehicle={data}
          isModalVisible={isModalVisible}
          handleFriendModal={handleFriendModal}
          isOfferModalVisible={isOfferModalVisible}
          handleOfferModal={handleOfferModal}
          handleBackBtn={handleBackBtn}
        />
        <Leasing
          price={CurrentPrice}
          interest={SpecialInterest || InterestRate}
          isHybrid={data?.IsHybrid}
        />
        <Equipment list={equipment} isLoading={equipmentLoading} />
        <Container as="footer" className="footer mb-7">
          <Row className="justify-content-md-center flex-column flex-md-row px-4">
            <Button onClick={handleOfferModal} className="mb-3 mr-md-3">
              {t('RequestInformation')}
            </Button>
            <Button
              onClick={handleFriendModal}
              className="btn-secondary mr-md-3 mb-3"
            >
              {t('ShareWithFriend')}
            </Button>
            <Button
              variant="secondary"
              onClick={handleBackBtn}
              className="mb-3 mr-md-3"
            >
              {t('BackToModels')}
            </Button>
            <Button variant="secondary" onClick={handleOtherBtn}>
              {t('BackToAllModels')}
            </Button>
            <Disclaimers modelFamily={ModelFamilyNameInCarDb} />
          </Row>
        </Container>
      </div>
    );
  } else {
    const nextPath = removeLastPathSegment(pathname);
    return <Redirect to={`${nextPath}${search}${hash}`} />;
  }
};

export default DetailView;
