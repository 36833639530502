import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';

import { postRequest } from '../utils/apiUtils';

import { Title } from './UIComponents/Typography';

export default function FriendModal({ show, onClose, urn, modelName }) {
  const { t } = useTranslation();

  const [isValid, setIsValid] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [formValues, setFormValues] = React.useState({});

  const handleFieldChange = e => {
    const id = e.target.id;

    setFormValues(formValues => ({
      ...formValues,
      [id]: e.target.value,
    }));
  };

  const handleClose = () => {
    setIsValid(false);
    setFormValues({});
    onClose();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === true) {
      const postBody = { ...formValues, Urn: urn };

      fetch('/api/shareOffer', postRequest(postBody)).then(res =>
        res.ok ? setIsSubmitted(true) : setIsSubmitted('error')
      );
    }

    setIsValid(true);
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        {isSubmitted ? null : (
          <Modal.Title className="mx-auto">{t('ShareWithFriend')}</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body className={isSubmitted && 'form-submitted'}>
        {!isSubmitted ? (
          <Form noValidate validated={isValid} onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={6} className="d-flex align-items-center">
                {modelName}
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="SenderName">
                  <Form.Label className="required">
                    {t('SenderName')}
                  </Form.Label>
                  <Form.Control
                    onKeyUp={handleFieldChange}
                    required
                    type="text"
                  />
                  <Form.Control.Feedback type="invalid">
                    <Trans i18nKey="RequiredField">
                      {{ field: t('SenderName') }} on kohustuslik
                    </Trans>
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="FriendEmail">
                  <Form.Label className="required">
                    {t('FriendEmail')}
                  </Form.Label>
                  <Form.Control
                    onKeyUp={handleFieldChange}
                    required
                    type="email"
                  />
                  <Form.Control.Feedback type="invalid">
                    <Trans i18nKey="RequiredField">
                      {{ field: t('FriendEmail') }} on kohustuslik
                    </Trans>
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="SenderMail">
                  <Form.Label className="required">
                    {t('SenderEmail')}
                  </Form.Label>
                  <Form.Control
                    onKeyUp={handleFieldChange}
                    required
                    type="email"
                  />
                  <Form.Control.Feedback type="invalid">
                    <Trans i18nKey="RequiredField">
                      {{ field: t('SenderEmail') }} on kohustuslik
                    </Trans>
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="SenderComment">
              <Form.Label className="required">{t('SenderComment')}</Form.Label>
              <Form.Control
                required
                onKeyUp={handleFieldChange}
                as="textarea"
                rows={3}
                defaultValue={formValues.Comment}
              />
              <Form.Control.Feedback type="invalid">
                <Trans i18nKey="RequiredField">
                  {{ field: t('SenderComment') }} on kohustuslik
                </Trans>
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="footer justify-content-end my-5">
              <Button
                variant="secondary"
                onClick={handleClose}
                size="sm"
                type="button"
              >
                {t('Close')}
              </Button>
              <Button className="ml-4" size="sm" type="submit">
                {t('Send')}
              </Button>
            </Row>
          </Form>
        ) : isSubmitted === 'error' ? (
          <Title type="error" size="4">
            {t('ErrorUnabelToSendMail')}
          </Title>
        ) : (
          <Title size="4">{t('RequestToFriendSent')}</Title>
        )}
      </Modal.Body>
    </Modal>
  );
}
