import * as React from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import useSearch from '../hooks/useSearch';
import useFilters from '../hooks/useFilters';
import useRetailers from '../hooks/useRetailers';
import useAsyncDebounce from '../hooks/useDebounce';
import useModelFamily from '../hooks/useModelFamily';

import { formatPrice, priceSorter } from '../utils';

import Hero from '../components/ModelList/Hero';
import Filters from '../components/ModelList/Filters';
import ListCard from '../components/ModelList/ListCard';
import Loader from '../components/Loader';
import OfferModal from '../components/OfferModal';
import Disclaimers from '../components/Disclaimers';
import ToyotaStripe from '../components/ToyotaStripe';
import Select from '../components/UIComponents/Select';
import { Title } from '../components/UIComponents/Typography';

const sortOptions = [
  {
    id: 'asc',
    value: 'PriceAscending',
  },
  {
    id: 'desc',
    value: 'PriceDescending',
  },
  {
    id: 'availability',
    value: 'SortByAvailability',
  },
  {
    id: 'priceDiff',
    value: 'BiggestDiscount',
  },
];

const ModelList = props => {
  const history = useHistory();
  const { t } = useTranslation();
  const { modelFamily, retailer } = useParams();
  const { isLoading, data, isError } = useModelFamily(modelFamily);
  const { data: retailers, isError: retailersError } = useRetailers(retailer);
  const { data: filters, isLoading: filtersLoading } = useFilters(modelFamily);

  const [sortOrder, setSortOrder] = React.useState('asc');
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState(null);
  const [hasFilterChanged, setHasFilterChanged] = React.useState(false);
  const [currentVehicle, setCurrentVehicle] = React.useState(null);

  React.useEffect(() => {
    if (filters) {
      setFilterValues({
        TransmissionTypes: [],
        BodyTypes: [],
        FuelTypes: [],
        DriveTrainTypes: [],
        CO2: filters.CO2,
        PriceRange: filters.PriceRange,
        ModelFamilyName: modelFamily,
      });
    }
  }, [filters, modelFamily]);

  React.useEffect(() => {
    if (props.location.search) {
      const lastSelected = document.getElementById(
        props.location.search.split('=')[1]
      );
      lastSelected?.scrollIntoView();
    }
  }, [props.location.search]);

  const {
    data: filteredCars,
    refetch,
    isLoading: filteredCarsLoading,
  } = useSearch(filterValues);
  const onFetchDataDebounce = useAsyncDebounce(refetch, 500);

  React.useEffect(() => {
    if (hasFilterChanged) onFetchDataDebounce(filterValues);
  }, [filterValues, hasFilterChanged, onFetchDataDebounce, refetch]);

  const currentData = hasFilterChanged ? filteredCars : data;
  const modelCount = currentData?.length ?? data?.length;
  const cheapestModel = data?.reduce((prev, curr) => {
    return prev.CurrentPrice < curr.CurrentPrice ? prev : curr;
  });
  const mostExpensiveModel = data?.reduce((prev, curr) => {
    return prev.CurrentPrice > curr.CurrentPrice ? prev : curr;
  });

  const handleSortOrder = id => setSortOrder(id);

  const handleModal = modelId => {
    const found = data.find(e => e.URN === modelId);
    setCurrentVehicle(found);
    setIsModalVisible(modelId ? modelId : false);
  };

  const handleOtherBtn = () => history.push(`/${retailer ? retailer : ''}`);

  const handleCreateBtn = () =>
    window.open(
      `${t('ToyotaHomePage')}/new-cars/${modelFamily}/index.json`,
      '_blank'
    );

  const handleScrollToTop = () => window.scrollTo(0, 0);

  if (isLoading) return <Loader />;

  if (isError) {
    history.push(`${retailer ? `/${retailer}` : ''}/404`);
    return null;
  }

  return (
    <div className="model-list">
      <ToyotaStripe />
      <Hero
        img={mostExpensiveModel.ThumbnailBig}
        model={cheapestModel.ModelFamilyName}
        price={formatPrice(cheapestModel.CurrentPrice)}
      />
      {filterValues && (
        <Filters
          filters={filters}
          isLoading={filtersLoading}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          setHasFilterChanged={setHasFilterChanged}
        />
      )}
      <div className="list-header px-3 mb-7">
        {filteredCarsLoading ? (
          <Loader />
        ) : modelCount ? (
          <>
            <Title size="1" uppercase>
              <Trans i18nKey="ModelsAvailable">
                Saadaval on {{ modelcount: modelCount }} autot,
              </Trans>
            </Title>
            <div className="d-flex align-items-center flex-wrap justify-content-center">
              <Title size="4" className="mr-2 mb-md-0">
                {t('SortedBy')}
              </Title>
              <Select
                flex
                id="sortOrder"
                activeId={sortOrder}
                onClick={handleSortOrder}
                options={sortOptions}
              />
            </div>
          </>
        ) : (
          <Title size="1" uppercase>
            {t('NoSuchModelsAvailable')}
          </Title>
        )}
      </div>
      <div className="list px-3 mx-xxl-10 mx-xl-8 mx-lg-5 mx-md-1">
        {priceSorter(currentData, sortOrder).map(model => (
          <ListCard
            key={model.URN}
            car={model}
            handleModal={handleModal}
            retailersError={retailersError}
          />
        ))}
      </div>
      <Container as="footer" className="footer mb-7">
        <Title className="mb-45 text-center" size="4" uppercase>
          {t('NoSuitableCar')}
        </Title>
        <Row className="justify-content-md-center flex-column flex-md-row px-4">
          <Button
            className="mr-md-4 mb-3"
            variant="secondary"
            onClick={handleScrollToTop}
          >
            {t('BackToTop')}
          </Button>
          <Button
            className="mr-md-4 mb-3"
            variant="secondary"
            onClick={handleOtherBtn}
          >
            {t('ShowAllOffers')}
          </Button>
          <Button variant="secondary" onClick={handleCreateBtn}>
            {t('CreateYourOwn')}
          </Button>
        </Row>
        <Disclaimers modelFamily={modelFamily} />
      </Container>
      {isModalVisible ? (
        <OfferModal
          retailers={retailers}
          show={Boolean(isModalVisible)}
          onClose={handleModal}
          urn={isModalVisible}
          models={currentData}
          vehicle={currentVehicle}
        />
      ) : null}
    </div>
  );
};

export default ModelList;
