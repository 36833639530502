import { useLocation } from 'react-router';
import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import useBreakPoints from '../../hooks/useBreakPoints';
import useGeneralRetailers from '../../hooks/useGeneralRetailers';

import ToyotaLogo from '../../assets/images/toyota_logo.svg';

import './styles.scss';

const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { data } = useGeneralRetailers();
  const { isSmallerThanMd } = useBreakPoints();

  const firstParamInPath = pathname.split('/')[1];
  const currentRetailer = data?.find(({ Key }) => Key === firstParamInPath);

  const handleLanguageChange = () =>
    (window.location.href = t('AlternateCountryUrl'));

  const handleToyotaLogo = () => (window.location.href = t('ToyotaHomePage'));

  const handleRetailer = () =>
    (window.location.href = currentRetailer?.HomePageUrl);

  return (
    <>
      <Navbar>
        <Navbar.Brand>
          <div className="logo-wrap" onClick={handleToyotaLogo}>
            <img src={ToyotaLogo} alt="Toyota logo" />
          </div>
        </Navbar.Brand>
        <Nav className="justify-content-between w-100">
          <Nav.Item>
            <Nav.Link
              href={`/${currentRetailer ? currentRetailer.Key : ''}`}
              eventKey={`/${currentRetailer ? currentRetailer.Key : ''}`}
              active
            >
              {t('SpecialOffers')}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {!isSmallerThanMd ? (
          <div className="d-flex">
            {t('AlternateCountryUrl') === '-' ? null : (
              <div
                onClick={handleLanguageChange}
                className={`language ${
                  currentRetailer ? 'with-retailer' : 'mr-md-5'
                }`}
              >
                {t('AlternativeCountryText')}
              </div>
            )}
            {currentRetailer ? (
              <Navbar.Brand className="retailer">
                <div onClick={handleRetailer} className="logo-wrap">
                  <img src={currentRetailer.LogoLocation} alt="retailerImg" />
                </div>
              </Navbar.Brand>
            ) : null}
          </div>
        ) : (
          <>
            {t('AlternateCountryUrl') === '-' ? null : (
              <div
                onClick={handleLanguageChange}
                className={`language ${currentRetailer ? '' : 'mr-md-5'}`}
              >
                {t('AlternativeCountryText')}
              </div>
            )}
          </>
        )}
      </Navbar>
      {isSmallerThanMd && currentRetailer ? (
        <Navbar className="sm-retailer-logo">
          <Navbar.Brand className="retailer">
            <div onClick={handleRetailer} className="logo-wrap-sm">
              <img src={currentRetailer.LogoLocation} alt="retailerImg" />
            </div>
          </Navbar.Brand>
        </Navbar>
      ) : null}
    </>
  );
};

export default Header;
