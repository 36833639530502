import { useQuery } from 'react-query';

import { apiRequest } from '../utils/apiUtils';

export default function useRetailers(retailer) {
  return useQuery(
    ['retailers', retailer],
    () => apiRequest(`/api/retailers${retailer ? `?code=${retailer}` : ''}`),
    {
      refetchOnWindowFocus: false,
    }
  );
}
