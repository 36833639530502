import { Col, Row } from 'react-bootstrap';
import './styles.scss';

export default function LeasingInterest({ isDetailView, CampaignIcons = [] }) {
  if (!isDetailView) {
    return (
      <Row>
        {CampaignIcons.map(src => (
          <Col xs="6" lg="6" md="12" xl="6">
            <img className="campaign-icon" src={src} alt="Campaign" />
          </Col>
        ))}
      </Row>
    );
  }
  return (
    <Row>
      {CampaignIcons.map(src => {
        return (
          <Col xs="6" lg="4" md="6" xl="4">
            <img className="campaign-icon" src={src} alt="Campaign" />
          </Col>
        );
      })}
    </Row>
  );
}
