import { useTranslation } from 'react-i18next';

import useModelfamilies from '../hooks/useModelfamilies';

import GridView from '../components/Landing/GridView';
import { Title } from '../components/UIComponents/Typography';

const Landing = () => {
  const { t } = useTranslation();
  const { isLoading, data, isError } = useModelfamilies();

  if (isError)
    return (
      <div className="landing py-4">
        <Title type="error">{t('ErrorTechnical')}</Title>
      </div>
    );

  return (
    <div className="landing py-4 px-2 px-md-2">
      <Title>{t('SpecialOffers')}</Title>
      <GridView models={data} loading={isLoading} />
    </div>
  );
};

export default Landing;
