import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ExampleModal = ({ onClose, show, props }) => (
  <Modal size="lg" show={show} onHide={onClose} centered {...props}>
    <Modal.Header closeButton>
      <Modal.Title>Modal title</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4>Example modal</h4>
      <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit.Veniam quos di
        recusandae minus enim, atque ex vel obcaecati fuga vero nulla eligendi
        soluta ipsum voluptatum!
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose} size="sm">
        Close
      </Button>
      <Button onClick={onClose} size="sm">
        Submit
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ExampleModal;
