import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import useGeneralRetailers from './hooks/useGeneralRetailers';

import Styling from './views/Styling';
import Landing from './views/Landing';
import ModelList from './views/ModelList';
import DetailView from './views/DetailView';
import NotFound from './views/NotFound';
import Loader from './components/Loader';
import useModelfamilies from './hooks/useModelfamilies';

const Router = () => {
  const { pathname } = useLocation();
  const { data, isLoading } = useGeneralRetailers();
  const { data: models, isLoading: modelsLoading } = useModelfamilies();

  const retailerPath = data?.map(({ Key }) => Key).join('|');
  const modelsPath = models?.map(({ Url }) => Url).join('|');

  if (isLoading || modelsLoading) <Loader />;

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route exact path={`/:retailer(${retailerPath})?`} component={Landing} />
      <Route exact path="/styling" component={Styling} />
      <Route path={`/:retailer(${retailerPath})?/404`} component={NotFound} />
      <Route
        exact
        path={`/:retailer(${retailerPath})?/:modelFamily(${modelsPath})`}
        component={ModelList}
      />
      <Route
        exact
        path={`/:retailer(${retailerPath})?/:modelFamily(${modelsPath})/:vehicleId`}
        component={DetailView}
      />
      {!isLoading && !modelsLoading && <Route component={NotFound} />}
    </Switch>
  );
};

export default Router;
