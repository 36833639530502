import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

export const Title = ({ size = 1, children, ...rest }) => {
  const Tag = `h${size}`;

  const classes = classNames('title', rest.className, {
    'title-uppercase': rest.uppercase,
    [`title-${rest.type}`]: rest.type,
  });

  return <Tag className={classes}>{children}</Tag>;
};

Title.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  uppercase: PropTypes.bool,
  type: PropTypes.oneOf(['error']),
  children: PropTypes.node.isRequired,
};
