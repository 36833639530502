export const apiRequest = async (url, options) => {
  const res = await fetch(url, options);

  if (!res.ok) {
    throw new Error('Network Error');
  }

  return await res.json();
};

export const postRequest = body => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});
