import Spinner from 'react-bootstrap/Spinner';

import './styles.scss';

export default function Loader() {
  return (
    <div className="loader-container">
      <Spinner animation="border" />
    </div>
  );
}
