import { useQuery } from 'react-query';

import { apiRequest, postRequest } from '../utils/apiUtils';

export default function useSearch(searchBody) {
  return useQuery(
    ['search', searchBody],
    async () => {
      const data = await apiRequest(
        `/api/vehicles/search`,
        postRequest(searchBody)
      );
      const indexedData = data.map((vehicle, index) => ({
        index,
        ...vehicle,
      }));
      return indexedData;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      keepPreviousData: true,
    }
  );
}
