/** @jsxRuntime classic */
import 'idempotent-babel-polyfill';
import 'react-app-polyfill/ie11';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { init, RouteTracker } from './utils/GoogleAnalytics';
import { initializeTagManager } from './utils/GoogleTags';

import Routes from './Routes';
import Header from './components/Header';
import MetaData from './components/MetaData';

import i18n from './i18n.js';

import './assets/scss/main.scss';

window.i18n = i18n;

const queryClient = new QueryClient();

initializeTagManager();

ReactDOM.render(
  <Suspense fallback="">
    <div className="app">
      <QueryClientProvider client={queryClient}>
        <MetaData />
        <BrowserRouter>
          {init() && <RouteTracker />}
          <Header />
          <Routes />
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  </Suspense>,
  document.getElementById('root')
);
