export const formatDate = date => {
  const [year, month, day] = date.split('T')[0].split('-');

  return `${day}.${month}.${year}`;
};

export const formatPrice = price =>
  price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const roundNumber2Points = number => Math.round(number * 100) / 100;

export const compareArrayNumbers = (arr1, arr2) =>
  arr1.length === arr2.length && arr1.every((val, idx) => val === arr2[idx]);

export const createMarkup = transCode => ({ __html: transCode });

export const isCurrentDate = date => {
  const currentDate = new Date().toISOString().split('T')[0];
  const inputDate = new Date(date).toISOString().split('T')[0];

  return currentDate >= inputDate;
};

export const priceSorter = (data, sortOrder) => {
  if (!data) return [];
  if (sortOrder === 'asc' || sortOrder === 'desc') {
    return data.sort((a, b) => {
      const order = sortOrder === 'asc' ? [a, b] : [b, a];

      if (order[0].CurrentPrice < order[1].CurrentPrice) return -1;
      if (order[0].CurrentPrice > order[1].CurrentPrice) return 1;
      return order[0].index - order[1].index;
    });
  }

  if (sortOrder === 'availability') {
    return data.sort((a, b) => {
      if (Date.parse(a.ETA) < Date.parse(b.ETA)) return -1;
      if (Date.parse(a.ETA) > Date.parse(b.ETA)) return 1;
      return a.index - b.index;
    });
  }

  if (sortOrder === 'priceDiff') {
    return data.sort((a, b) => {
      const aDiff = a.Prices.CurrentPrice - a.Prices.OriginalPrice;
      const bDiff = b.Prices.CurrentPrice - b.Prices.OriginalPrice;

      if (aDiff < bDiff) return -1;
      if (aDiff > bDiff) return 1;
      return a.index - b.index;
    });
  }
};

export const removeLastPathSegment = pathname => {
  const segments = pathname.split('/');
  segments.pop();
  return segments.join('/');
};
