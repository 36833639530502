import Slider, { Range } from 'rc-slider';

import './style.scss';

export const CustomRange = ({
  min,
  max,
  sliderValue,
  onSliderChange,
  step = 1,
  sliderValuesPrefix = '',
  sliderValuesSufix = '',
}) => (
  <div className="slider__wrap">
    <Range
      min={min}
      max={max}
      step={step}
      value={sliderValue}
      onChange={onSliderChange}
    />
    <div className="slider__values">
      <span>{`${sliderValuesPrefix} ${sliderValue[0]} ${sliderValuesSufix}`}</span>
      <span>{`${sliderValuesPrefix} ${sliderValue[1]} ${sliderValuesSufix}`}</span>
    </div>
  </div>
);

export const CustomSlider = ({
  min,
  max,
  sliderValue,
  onSliderChange,
  step = 1,
  sliderValuesPrefix = '',
}) => {
  return (
    <div className="slider__wrap">
      <Slider
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        onChange={onSliderChange}
      />
      <div className="slider__values">
        <span>{`${sliderValuesPrefix} ${sliderValue}`}</span>
      </div>
    </div>
  );
};

export default CustomSlider;
