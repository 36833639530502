import * as React from 'react';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';

export default function GoogleAnalytics({ location }) {
  React.useEffect(() => {
    const logPageChange = (pathname, search = '') => {
      const page = pathname + search;
      const { location } = window;

      ReactGA.set({
        page,
        location: `${location.origin}${page}`,
      });
      ReactGA.pageview(page);
    };

    logPageChange(location.pathname, location.search);
  }, [location]);

  return null;
}

export const RouteTracker = () => <Route component={GoogleAnalytics} />;

export const init = async (options = {}) => {
  const trackingCode = process.env.REACT_APP_TRACKING_CODE;
  const isGAEnabled = !!trackingCode;

  if (isGAEnabled) {
    ReactGA.initialize(trackingCode, {
      ...options,
    });
  }

  return isGAEnabled;
};
