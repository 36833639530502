import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiChevronLeft } from '@mdi/js';

import useBreakPoints from '../../hooks/useBreakPoints';

import { Title } from '../UIComponents/Typography';

export default function Hero({ img, model, price }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { retailer } = useParams();
  const { isXs } = useBreakPoints();

  const handleBackBtn = () => {
    history.push(`/${retailer ? retailer : ''}`);
  };

  return (
    <Container fluid className="hero px-4 px-lg-6 px-xl-1">
      <Row>
        <Col
          className="d-flex justify-content-lg-end justify-content-start mt-n5 mt-md-n2 pt-md-3 pt-xl-0 mt-xl-0"
          md={12}
          lg={3}
        >
          <Button variant="functional" onClick={handleBackBtn}>
            <Icon path={mdiChevronLeft} size={1} />
            {!isXs && <span>{t('ButtonBack')}</span>}
          </Button>
        </Col>
        <Col md={12} xl={6} className="px-md-5">
          <Title size="1" uppercase>
            {model}
          </Title>
          <Title size="3">
            {t('PriceForm').toUpperCase()}
            <br /> {price} €
          </Title>
          <img src={img} alt={model} />
        </Col>
      </Row>
    </Container>
  );
}
