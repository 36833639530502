import { useQuery } from 'react-query';

import { apiRequest } from '../utils/apiUtils';

export default function useGeneralRetailers() {
  return useQuery(
    'generalRetailers',
    () => apiRequest('/api/configuration/retailers'),
    { refetchOnWindowFocus: false }
  );
}
