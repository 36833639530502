import { Redirect, useLocation } from 'react-router-dom';
import { removeLastPathSegment } from '../utils';

const NotFound = () => {
  let { pathname, search, hash } = useLocation();
  const nextPath = removeLastPathSegment(pathname);
  return <Redirect to={`${nextPath}${search}${hash}`} />;
};

export default NotFound;
