import { useParams } from 'react-router';
import { useTranslation, Trans } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiArrowLeft,
  mdiCheck,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
  Dot,
} from 'pure-react-carousel';
import { Button, Col, Container, Row, Badge } from 'react-bootstrap';

import useRetailers from '../../hooks/useRetailers';
import useBreakPoints from '../../hooks/useBreakPoints';
import { formatDate, isCurrentDate } from '../../utils';

import PriceBox from '../PriceBox';
import OfferModal from '../OfferModal';
import FriendModal from '../FriendModal';
import LeasingInterest from '../LeasingInterest';
import { Text, Title } from '../UIComponents/Typography';

import 'pure-react-carousel/dist/react-carousel.es.css';

const iconColor = '#6a6b70';

export default function DetailViewHero({
  vehicle = {},
  isModalVisible,
  handleFriendModal,
  isOfferModalVisible,
  handleOfferModal,
  handleBackBtn,
}) {
  const {
    VehicleImages = [],
    ModelFamilyName,
    ModelName,
    Accessories,
    Prices,
    MonthlyPmt,
    URN,
    SpecialInterest,
    InterestRate,
    IsHybrid,
    FiveYearsWarranty,
    ColorName,
    ETA,
    ReservedUntil,
    InteriourName,
    CampaignIcons,
    EuriborText,
  } = vehicle;
  const { t } = useTranslation();
  const { retailer } = useParams();
  const { data: retailers } = useRetailers(retailer);
  const { isSmallerThanLg } = useBreakPoints();

  const availableFrom = formatDate(ETA);
  const isCarAvailable = isCurrentDate(ETA);

  return (
    <>
      <CarouselProvider
        naturalSlideHeight={200}
        naturalSlideWidth={190}
        totalSlides={VehicleImages?.length ?? 0}
        infinite={true}
        isIntrinsicHeight
      >
        <Container fluid className="detail-view-hero px-3 px-md-5 px-lg-7 pb-3">
          <Row>
            <Col
              xl={1}
              className="d-flex flex-column align-items-start align-items-xl-center mt-n5 mt-xl-0"
            >
              <Button variant="icon" onClick={handleBackBtn}>
                <Icon path={mdiArrowLeft} size={1} />
              </Button>
            </Col>
            <Col xl={11} className="d-flex justify-content-start">
              <Title>{ModelFamilyName}</Title>
            </Col>
          </Row>
          <Row className="px-3">
            <Col
              xs={12}
              lg={3}
              xl={{ span: 3, offset: 1 }}
              className="mb-2 d-flex flex-column justify-content-between"
            >
              <Row className="flex-column mb-3">
                <Title size="5">{ModelName}</Title>
                {ReservedUntil ? (
                  <Badge className="my-1" variant="light">
                    {t('Booked')}
                  </Badge>
                ) : null}
                <Text className="mt-1">
                  {t('Color')}: <Text>{ColorName}</Text>
                </Text>
                {InteriourName ? (
                  <Text>
                    {t('InteriorColour')} <Text>{InteriourName}</Text>
                  </Text>
                ) : null}
                {Accessories ? (
                  <>
                    <Text className="mt-2">{t('Accessories')}</Text>
                    {Accessories.map(acc => (
                      <Text key={acc} className="detail-view-accessory">
                        <Icon color={iconColor} path={mdiCheck} size={1} />{' '}
                        <span>{acc}</span>
                      </Text>
                    ))}
                  </>
                ) : null}
                <Text className="pt-3">
                  {isCarAvailable ? (
                    <span className="text-capitalize">{t('Available')}</span>
                  ) : (
                    <Trans i18nKey="AvailableFrom">
                      Saadavus:
                      <span className="text-capitalize">
                        alates {{ availableFrom }}
                      </span>
                    </Trans>
                  )}
                </Text>
              </Row>
              <Row>
                {isSmallerThanLg && (
                  <LeasingInterest
                    SpecialInterest={SpecialInterest}
                    InterestRate={InterestRate}
                    IsHybrid={IsHybrid}
                    FiveYearsWarranty={FiveYearsWarranty}
                    CampaignIcons={CampaignIcons}
                    isDetailView
                  />
                )}
              </Row>
            </Col>
            <Col
              xs={12}
              lg={6}
              xl={5}
              className="mb-2 mt-2 mt-lg-5 px-1 px-md-2"
            >
              <Row className="flex-nowrap align-items-center">
                {isSmallerThanLg ? (
                  <Col xs={2} className="px-0 d-flex justify-content-end">
                    <ButtonBack className="carousel-btn">
                      <Icon color={iconColor} path={mdiChevronLeft} size={2} />
                    </ButtonBack>
                  </Col>
                ) : null}
                <Col xs={isSmallerThanLg ? 8 : 12} className="px-3">
                  <Slider>
                    {VehicleImages.map(({ BigImageUrl }, idx) => (
                      <Slide key={idx} index={idx}>
                        <Image hasMasterSpinner src={BigImageUrl} />
                      </Slide>
                    ))}
                  </Slider>
                </Col>
                {isSmallerThanLg ? (
                  <Col xs={2} className="px-0 d-flex justify-content-start">
                    <ButtonNext className="carousel-btn">
                      <Icon color={iconColor} path={mdiChevronRight} size={2} />
                    </ButtonNext>
                  </Col>
                ) : null}
              </Row>
            </Col>
            <Col
              xs={12}
              lg={3}
              xl={3}
              className="d-flex flex-column flex-md-row flex-lg-column justify-content-around px-0"
            >
              <PriceBox
                Prices={Prices}
                MonthlyPmt={MonthlyPmt}
                InterestRate={InterestRate}
                IsHybrid={IsHybrid}
                SpecialInterest={SpecialInterest}
                EuriborText={EuriborText}
              />
              <div className="d-flex flex-column align-items-center align-items-lg-end">
                <Button onClick={handleOfferModal} className="mb-3">
                  {t('RequestInformation')}
                </Button>
                <Button onClick={handleFriendModal} className="btn-secondary">
                  {t('ShareWithFriend')}
                </Button>
              </div>
            </Col>
          </Row>
          {!isSmallerThanLg && (
            <Row>
              <Col md={11} lg={11} xl={{ span: 11, offset: 1 }}>
                <LeasingInterest
                  SpecialInterest={SpecialInterest}
                  InterestRate={InterestRate}
                  IsHybrid={IsHybrid}
                  FiveYearsWarranty={FiveYearsWarranty}
                  CampaignIcons={CampaignIcons}
                  isDetailView
                />
              </Col>
            </Row>
          )}
          {isSmallerThanLg ? null : (
            <Row className="mt-5">
              <Col xs={1}>
                <ButtonBack className="carousel-btn">
                  <Icon color={iconColor} path={mdiChevronLeft} size={3} />
                </ButtonBack>
              </Col>
              <Col xs={10} className="d-flex">
                {VehicleImages.map(({ BigImageUrl }, idx) => (
                  <Dot key={idx} className="carousel-dot flex-fill" slide={idx}>
                    <Image src={BigImageUrl} />
                  </Dot>
                ))}
              </Col>
              <Col xs={1}>
                <ButtonNext className="carousel-btn">
                  <Icon color={iconColor} path={mdiChevronRight} size={3} />
                </ButtonNext>
              </Col>
            </Row>
          )}
        </Container>
      </CarouselProvider>
      <FriendModal
        show={isModalVisible}
        onClose={handleFriendModal}
        urn={URN}
        modelName={ModelName}
      />
      {isOfferModalVisible ? (
        <OfferModal
          retailers={retailers}
          show={isOfferModalVisible}
          onClose={handleOfferModal}
          urn={URN}
          modelName={ModelName}
          vehicle={vehicle}
        />
      ) : null}
    </>
  );
}
