import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Form, FormControl, InputGroup } from 'react-bootstrap';

import useLeasing from '../../hooks/useLeasing';

import { Text, Title } from '../UIComponents/Typography';

const initialState = {
  downp: 15,
  residual: 30,
  period: 5,
  leasingtype: 1,
};
const numbersRegex = /^[0-9]*$/;

export default function Leasing({ price, interest, isHybrid }) {
  const { t } = useTranslation();

  const [formValues, setFormValues] = React.useState({
    ...initialState,
    residual: isHybrid ? 40 : 30,
    price,
    interest,
    downpNr: Math.round((initialState.downp * price) / 100),
    residualNr: Math.round(((isHybrid ? 40 : 30) * price) / 100),
  });
  const { data } = useLeasing(formValues);

  const handleFormChange = (value, type) => {
    if (!value.match(numbersRegex)) return;
    if (type === 'downpNr' || type === 'residualNr') {
      const percentage = Math.round((value * 100) / price);

      setFormValues(prevState => ({
        ...prevState,
        [type.slice(0, -2)]: percentage,
        [type]: value,
      }));
      return;
    }

    if (type === 'downp' || type === 'residual') {
      const nrValue = Math.round((value * price) / 100);

      setFormValues(prevState => ({
        ...prevState,
        [type]: value,
        [`${type}Nr`]: nrValue,
      }));
      return;
    }

    setFormValues(prevState => ({ ...prevState, [type]: value }));
  };

  return (
    <div className="leasing">
      <Container fluid className="px-3 px-md-6 px-lg-6 px-xl-8 py-5">
        <Title className="text-center mb-5" size="2">
          {t('Leaseing')}
        </Title>
        <Form>
          <Form.Row className="justify-content-md-center">
            <Col xs={12} md={6} lg={3} xl={2}>
              <Form.Group controlId="leasingtype">
                <Form.Label className="required">{t('LeasingType')}</Form.Label>
                <Form.Control
                  defaultValue={formValues.leasingtype}
                  as="select"
                  custom
                  onChange={e =>
                    handleFormChange(e.target.value, 'leasingtype')
                  }
                >
                  <option value={1}>{t('OperatingLease')}</option>
                  <option value={2}>{t('FinanceLease')}</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3} xl={2}>
              <Form.Group controlId="period">
                <Form.Label className="required">{t('LeasePeriod')}</Form.Label>
                <Form.Control
                  defaultValue={formValues.period}
                  as="select"
                  custom
                  onChange={e => handleFormChange(e.target.value, 'period')}
                >
                  <option value={null}>{t('LeasePeriodChoose')}</option>
                  <option value={1}>{t('LeasePeriodChoose1')}</option>
                  <option value={2}>{t('LeasePeriodChoose2')}</option>
                  <option value={3}>{t('LeasePeriodChoose3')}</option>
                  <option value={4}>{t('LeasePeriodChoose4')}</option>
                  <option value={5}>{t('LeasePeriodChoose5')}</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg="auto">
              <Form.Group controlId="downp">
                <Form.Label className="required">{t('DownPayment')}</Form.Label>
                <InputGroup>
                  <Form.Control
                    value={formValues.downp}
                    onChange={e => handleFormChange(e.target.value, 'downp')}
                    type="text"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup.Append>
                  <FormControl
                    value={formValues.downpNr}
                    onChange={e => handleFormChange(e.target.value, 'downpNr')}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>€</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg="auto">
              <Form.Group controlId="residual">
                <Form.Label className="required">{t('Residiual')}</Form.Label>
                <InputGroup>
                  <FormControl
                    value={formValues.residual}
                    onChange={e => handleFormChange(e.target.value, 'residual')}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup.Append>
                  <FormControl
                    value={formValues.residualNr}
                    onChange={e =>
                      handleFormChange(e.target.value, 'residualNr')
                    }
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>€</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xs={12} md={3} lg="auto">
              <Form.Group controlId="interest">
                <Form.Label className="required">{t('Interest')}</Form.Label>
                <InputGroup>
                  <FormControl
                    defaultValue={formValues.interest}
                    onKeyUp={e => handleFormChange(e.target.value, 'interest')}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col
              lg={1}
              xl={1}
              md={2}
              className="d-flex flex-column pb-3 align-items-center justify-content-end ml-lg-3"
            >
              <Text size="lg">€ {data ? Math.round(data) : '-'}</Text>
              <Text size="sm" type="disabled">
                / {t('Month')}
                <div className="underline" />
              </Text>
            </Col>
          </Form.Row>
        </Form>
      </Container>
    </div>
  );
}
