import React from 'react';
import MetaTags from 'react-meta-tags';
import { useTranslation } from 'react-i18next';

const MetaData = () => {
  const { t } = useTranslation();

  return (
    <MetaTags>
      <title>{t('MetaTitle')}</title>
      <meta name="description" content={t('MetaDescription')} />
      <meta name="keywords" content={t('MetaKeywords')} />
    </MetaTags>
  );
};

export default MetaData;
