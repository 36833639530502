import { useQuery } from 'react-query';

import { apiRequest } from '../utils/apiUtils';

export default function useVehicle(vehicleURN) {
  return useQuery(
    ['vehicle', vehicleURN],
    () => apiRequest(`/api/vehicle/${vehicleURN}`),
    {
      refetchOnWindowFocus: false,
    }
  );
}
