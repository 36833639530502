import { useQuery } from 'react-query';

import { apiRequest } from '../utils/apiUtils';

export default function useModelFamily(familyUrl) {
  return useQuery(
    ['modelFamily', familyUrl],
    async () => {
      const data = await apiRequest(`/api/vehicles?modelFamily=${familyUrl}`);
      const indexedData = data.map((vehicle, index) => ({
        index,
        ...vehicle,
      }));
      return indexedData;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
