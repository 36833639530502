import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import { formatPrice } from '../../utils';

import { Text } from '../UIComponents/Typography';

const ModelFamilyCard = ({ model }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { retailer } = useParams();
  const {
    ModelName,
    LowestPrice,
    Url,
    ThumbnailSmall,
    ModelCodeForGTag,
    ModelNameForGTag,
  } = model;

  const price = formatPrice(LowestPrice);

  const handleCardClick = () => {
    window.tngaDataLayer.event = {
      name: 'workflowevent',
      componentName: 'stock-cars-search',
      componentGroup: 'tools',
      action: 'workflow-step',
      label: 'select-model',
    };
    window.tngaDataLayer.workflow = {
      name: 'stock-cars-search',
      step: 'select-model',
      conversion: '0',
    };
    window.tngaDataLayer.product = [
      {
        category: 'cars',
        subCategory: 'new-cars',
        productInfo: {
          productId: ModelCodeForGTag,
          productName: ModelNameForGTag,
          tmeModelId: ModelCodeForGTag,
          modelName: ModelNameForGTag,
        },
      },
    ];
    window.dataLayer.push({ event: 'workflowevent' });
    history.push(`${retailer ? `/${retailer}` : ''}/${Url}`);
  };

  return (
    <Card
      className="model-family-card mb-5 mb-xl-4"
      text="muted"
      onClick={handleCardClick}
    >
      <Card.Img className="mx-auto" variant="top" src={ThumbnailSmall} />
      <Card.Body>
        <Card.Title>{ModelName}</Card.Title>
        <p>
          <Text type="disabled">
            {t('PriceForm')} {price} €
          </Text>
        </p>
        <hr />
      </Card.Body>
    </Card>
  );
};

export default ModelFamilyCard;
