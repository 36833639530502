import React, { useState } from 'react';
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Badge,
  InputGroup,
  FormControl,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { mdiChevronLeft, mdiChevronRight, mdiPencilOutline } from '@mdi/js';
import Icon from '@mdi/react';

import CheckBox from '../../components/UIComponents/Checkbox';
import Radio from '../../components/UIComponents/Radio';
import Switch from 'react-bootstrap/esm/Switch';
import ExampleModal from './ExampleModal';
import Select from '../../components/UIComponents/Select';
import Slider, { CustomRange } from '../../components/UIComponents/Slider';

const mockOptions = [
  { id: 1, value: 'Option 1' },
  { id: 2, value: 'Option 2' },
  { id: 3, value: 'Option 3' },
];

const Styling = () => {
  const [selectionValue, setSelectionValue] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectValue, setSelectValue] = useState(1);
  const [rangeValue, setRangeValue] = useState([1, 450]);
  const [sliderValue, setSliderValue] = useState(15);

  const onSliderChange = val => {
    setRangeValue(val);
  };

  return (
    <>
      <Container fluid className="mt-4">
        <Row className="mb-4">
          <Col xs={12} md={3} className="d-flex flex-column align-items-start">
            <h2>Buttons</h2>
            <Button className="mb-2" size="sm">
              Kinnita
            </Button>
            <Button variant="secondary" className="mb-2" size="lg">
              Loobu
            </Button>
            <Button variant="functional">Tagasi</Button>
          </Col>
          <Col xs={12} md={3} className="d-flex flex-column align-items-start">
            <h2>Buttons with icon</h2>
            <Button className="mb-2" size="sm">
              <span>Edasi</span> <Icon path={mdiChevronRight} size={1} />
            </Button>
            <Button variant="secondary" className="mb-2" size="lg">
              <Icon path={mdiChevronLeft} size={1} /> <span>Tagasi</span>
            </Button>
            <Button variant="functional">
              <Icon path={mdiChevronLeft} size={1} /> <span>Tagasi</span>
            </Button>
          </Col>
          <Col xs={12} md={3} className="d-flex flex-column align-items-start">
            <h2>Link button</h2>
            <Button variant="link" className="mb-2">
              <span>Kodulehele</span> <Icon path={mdiChevronRight} size={1} />
            </Button>
            <Button variant="link" className="mb-2">
              <Icon path={mdiPencilOutline} size={1} /> <span>Edit</span>
            </Button>
            <h2>Icon button</h2>
            <Button variant="icon">
              <Icon path={mdiChevronLeft} size={1} />
            </Button>
          </Col>
          <Col xs={12} md={3}>
            <h2>Selection controls</h2>
            <p>
              <CheckBox
                checked={selectionValue}
                onClick={() => setSelectionValue(!selectionValue)}
              ></CheckBox>
              <label htmlFor="">Checkbox</label>
            </p>
            <p>
              <Radio
                checked={selectionValue}
                onClick={() => setSelectionValue(!selectionValue)}
              ></Radio>
              <label htmlFor="">Radio</label>
            </p>
            <p>
              <Switch
                id="testSwitch"
                checked={selectionValue}
                onChange={() => setSelectionValue(!selectionValue)}
                label="Switch"
              />
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            <h2>Input</h2>
            <Form.Group controlId="formBasicEmail1">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
              <Form.Text className="error-text show">
                Please enter valid email
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicEmail2">
              <Form.Label className="required">Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
              <Form.Text className="error-text">
                Please enter valid email
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicArea">
              <Form.Label className="required">Text area</Form.Label>
              <Form.Control as="textarea" placeholder="Text..." />
              <Form.Text className="text-muted show">Type something</Form.Text>
            </Form.Group>
          </Col>
          <Col xs={12} md={3}>
            <h2>Other form elements</h2>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label>Bootstrap select</Form.Label>
              <Form.Control as="select" custom>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="select1">Custom select</Form.Label>
              <Select
                activeId={selectValue}
                onClick={setSelectValue}
                options={mockOptions}
                id="select1"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="select2">Long custom select</Form.Label>
              <Select
                activeId={selectValue}
                onClick={setSelectValue}
                options={mockOptions}
                id="select2"
                flex
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={3} className="d-flex flex-column align-items-start">
            <h2>Other</h2>
            <Badge className="mb-2">Broneeritud</Badge>
            <Button
              className="mb-2"
              size="sm"
              onClick={() => setModalOpen(true)}
            >
              Modal
            </Button>
          </Col>
          <Col xs={12} md={3}>
            <h2>Other</h2>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Money"
                aria-label="money"
                aria-describedby="basic-addon1"
              />
              <InputGroup.Append>
                <InputGroup.Text id="basic-addon1">€</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <Tabs activeKey="t1">
              <Tab title="Tab 1" eventKey="t1" />
              <Tab title="Tab 2" eventKey="t2" />
            </Tabs>
            <CustomRange
              min={1}
              max={1500}
              sliderValue={rangeValue}
              onSliderChange={onSliderChange}
              sliderValuesPrefix={'€'}
            />
            <Slider
              min={1}
              max={150}
              sliderValue={sliderValue}
              onSliderChange={val => setSliderValue(val)}
              sliderValuesPrefix={'€'}
            />
          </Col>
        </Row>
      </Container>
      <ExampleModal show={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default Styling;
