import { useQuery } from 'react-query';

import { apiRequest } from '../utils/apiUtils';

export default function useEquipment(urn) {
  return useQuery(
    'equipment',
    () => apiRequest(`/api/vehicle/${urn}/equipment`),
    {
      refetchOnWindowFocus: false,
    }
  );
}
