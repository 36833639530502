import { Trans, useTranslation } from 'react-i18next';

import { formatPrice } from '../../utils';

import { Text } from '../UIComponents/Typography';

import './styles.scss';

export default function PriceBox({
  MonthlyPmt,
  Prices,
  InterestRate,
  IsHybrid,
  SpecialInterest,
  EuriborText,
  IsDetailsView = false,
}) {
  const { t } = useTranslation();
  const { CurrentPrice, OriginalPrice } = Prices;

  const hasDiscount = CurrentPrice < OriginalPrice;

  let interest;

  if (!IsDetailsView) {
    interest = (SpecialInterest || InterestRate)
      .toString()
      .split('.')
      .join(',');
  }

  return (
    <div className="price-box p-3 mb-4">
      {hasDiscount ? (
        <>
          <Text type="disabled">{`${t('RegularPrice')} ${formatPrice(
            OriginalPrice
          )} €`}</Text>
          <Text>
            {t('SpecialPrice')}{' '}
            <Text size="lg" strong type="error">{`${formatPrice(
              CurrentPrice
            )} €`}</Text>
          </Text>
        </>
      ) : (
        <Text>
          {t('Price')}{' '}
          <Text size="lg" strong>{`${formatPrice(CurrentPrice)} €`}</Text>
        </Text>
      )}
      <Text>
        <Trans i18nKey="MonthlyPayment">Kuumaks {{ MonthlyPmt }} €</Trans>
      </Text>
      <Text type="disabled" size="sm">
        {t('TaxIncluded')}
      </Text>
      {!IsDetailsView && (
        <div className="leasing-interest mt-4">
          <div className={`interest ${IsHybrid && 'hybrid'} ${'list-icon'}`}>
            <Text>{`${interest}%`}</Text>
          </div>
          <div
            className={`interest-text ${IsHybrid && 'hybrid'} ${'list-icon'}`}
          >
            <Text strong className="interest-text-capital">
              {IsHybrid ? t('HybridLeasingInterest') : t('LeasingInterest')}
            </Text>
            <Text>{EuriborText}</Text>
          </div>
        </div>
      )}
    </div>
  );
}
