import { Container, Row, Col } from 'react-bootstrap';
import _chunk from 'lodash.chunk';

import useBreakPoints from '../../hooks/useBreakPoints';

import ModelFamilyCard from './ModelFamilyCard';
import Loader from '../Loader';

const GridView = ({ models = [], loading }) => {
  const { isLg } = useBreakPoints();

  if (loading) return <Loader />;

  return (
    <Container fluid>
      {models &&
        _chunk(models, isLg ? 3 : 4).map((row, idx) => (
          <Row
            key={idx}
            xl={4}
            lg={3}
            md={2}
            sm={2}
            xs={1}
            className="mb-xl-5 px-md-6"
          >
            {row.map(model => (
              <Col key={model.ModelName}>
                <ModelFamilyCard model={model} />
              </Col>
            ))}
          </Row>
        ))}
    </Container>
  );
};

export default GridView;
